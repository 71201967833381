<script>
import { mapState } from 'vuex'
import Config from './Config'
import Gen from '@libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'

export default {
  data(){
    return {
      filter: {},
      row: {},
      input:{},
      sort:{},
      mrValidation: {},
      data: {data: false},
      duration:35000,
      pattern:{
        name:/^[a-zA-Z '.,]+$/,
        username:/^[a-zA-Z0-9_.]+$/,
        phone:/^(?=[1-9])[0-9]+$/,
      },
    }
  },
  computed:{
    ...mapState({
      production: state => state.production,
      apiUrl: state => state.apiUrl,
      baseUrl: state => state.baseUrl,
      webTitle: state => state.webTitle,
      userToken: state => state.userToken,
      user: state => state.user,
    }),
    loadingOverlay:{
      get(){ return this.$root.var.loadingOverlay },
      set(v){ return this.$root.var.loadingOverlay = v },
    },
    Config(){ return Config },

    pageSlug(){ return this.$route.params.pageSlug },
    pageId(){ return this.$route.params.pageId },
    isAdd(){ return this.pageSlug == "add" },
    isList(){ return !this.pageSlug },
    isSorting(){return this.$route.query.sorting&&this.isList},
    isDelete(){return this.$route.query.delete&&this.isList},

    dataList(){return this.data.data },

    pageTitle(){return this.$route.meta?.pageTitle||""},
    pageTitleForm(){return (this.pageSlug == 'add' ? 'Add' : 'Edit')+' '+this.pageTitle},
    modulePage(){return this.$route.meta.menu},

    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "page", newValue);
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    perPage:{
      // getter
      get:function() {
        return this.filter.shown||this.$route.query.shown||Config.dataShown
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", newValue);
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    apiParams(){ return _.assign(_.clone(this.filter), _.clone(this.apiQuery||{})) },
    apiQuery(){return this.$route.query||{}},

    notFound(){
      if (this.data.data === false) return false
      if (this.data.data.length > 0) return false
      if (this.$route.query.search) {
        return "Search with keyword '" + this.$route.query.search + "' not found"
      }
      return this.pageTitle + " not found"
    },
    filterNotEmpty(){
      return $.grep(Object.keys(this.filter)||[], (attr)=>{
        return this.filter[attr] && attr!='page'
      }).length >= 1
    },
    dragOptions() {
      return {
        scrollSensitivity: 200,
        forceFallback: true,
        animation: 300,
        transition: 0,
        ghostClass: "ghost"
      };
    }
  },
  mounted() {
    this.filter = _.clone(this.$route?.query)
    if(this.user.isActive == "N"){
      this.$store.commit("logoutUserToken")
    }
    else if(!this.user){
      this.$store.commit("logoutUserToken")
    }
  },
  methods:{
    updateAlt(v, row, alt){
      row[alt] = v.target.value
    },
    isType(data){
      return $.type(data)
    },
    apiGetValProfile(){
      Gen.apiRest(
        "/get/"+"doCheck"
      )
      .catch((err)=>{
        if(err.response.data.message == "Unauthorized"){
          this.$store.commit("logoutUserToken")      
          this.$router.push({name:"Login"})      
        }
      })
    },
    getShortTxt:function(txt,length=15,comma="...",position="last"){
			if(!txt)return'';
			length = typeof length == undefined?15:length;
			comma = typeof comma == undefined?"...":comma;
			if(!txt) return txt;
			if(txt.length>length){
				if(position=="first"){
					let crop = txt.substr((txt.length-length)-1);
					return comma+crop;
				}else{
					let crop = txt.substr(0,length);
					return crop+comma;
				}
			}
			return txt;
		},
    apiGet(params = {}){
      if(this.pageSlug) this.loadingOverlay = true
      this.data.data = false
      Gen.apiRest(
        "/get/"+this.modulePage+
        (this.pageSlug?"/"+this.pageSlug:"")+
        (this.pageId?"/"+this.pageId:""), 
        {
          params: Object.assign({}, this.apiParams, params.query||{}, params.sort||{})
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        if(!this.isList){
          this.setFocusForm()
        }
        
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
    setFocusForm(){
      if(this.$refs.VForm.$el){
        let el = $(this.$refs.VForm.$el);
        if(el.find(':input:not([type=hidden])').length){
          el.find(':input:not([type=hidden]):first').focus()
        }else if(el.find('textarea').length){
          el.find('textarea:first').focus()
        }
      }
    },
    setTitle(){
      $("title").html(this.pageTitle+' - '+this.webTitle)
    },
    doFilter(){
      if(this.exportType && this.filter){
        let f = Object.values(this.filter).filter((attr)=>attr)
        if(f.length>=1){ 
          this.exportType = 'filtered'
        }else{
          this.exportType = 'all'
        }
      }

      this.$router.push({name:this.$route.name,query:Object.assign({},_.clone(this.filter), {page:1})}).catch(()=>{});
    },
    doSort(){
      if(this.exportType && this.sort){
        let f = Object.values(this.sort).filter((attr)=>attr)
        if(f.length>=1){ 
          this.exportType = 'filtered'
        }else{
          this.exportType = 'all'
        }
      }

      this.$router.push({name:this.$route.name,query:Object.assign({},_.clone(this.sort), {page:1})}).catch(()=>{});
    },
    doReset(){
      this.filter={}
      this.$router.push({name:this.$route.name,query:{page:1}});
    },
    doChangeStatus(k,v){
      let id = v[this.idKey]
      let changeStatus = v[this.statusKey] == "Y" ? "N" : "Y"
      global.Swal.fire({
        title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
        customClass: {
          confirmButton: 'btn btn-custom',
          cancelButton: 'btn btn-outline-custom ml-1',
        },
        buttonsStyling: false, 
      }).then((result) => {
        if (result.value) {
          this.loadingOverlay=true

          Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'status',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[this.statusKey] = changeStatus

              global.Swal.fire({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })
            })
            .catch((err)=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
              }
              this.doSetAlertForm(err);
            })
        }
      })
    },
    doChangeStatusCategory(k,v){
      let id = v[this.idKey]
      let changeStatus = v[this.statusKey] == "Y" ? "N" : "Y"
      global.Swal.fire({
        title: `Are You Sure to Change Status?`,
        icon: 'warning',
        text: 'This category already has articles. The status of articles linked to this category will also be changed.',
        showCancelButton: true,
        confirmButtonText: `Yes, change it!`,
        customClass: {
          confirmButton: 'btn btn-custom',
          cancelButton: 'btn btn-outline-custom ml-1',
        },
        buttonsStyling: false, 
      }).then((result) => {
        if (result.value) {
          this.loadingOverlay=true

          Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'status',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[this.statusKey] = changeStatus

              global.Swal.fire({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })
            })
            .catch((err)=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
              }
              this.doSetAlertForm(err);
            })
        }
      })
    },
    doDelete(k,v){
      let id = v[this.idKey]
      global.Swal.fire({
        title: 'Are you sure to delete?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-custom',
          cancelButton: 'btn btn-outline-custom ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadingOverlay = true
          this.openForm = false;
          Gen.apiRest("/do/"+this.modulePage,{data:{type:'delete',id:id}}, "POST")
            .then((res)=>{
              if(res.data.code==400){
                this.loadingOverlay = false
                return global.Swal.fire({
                  title: res.data.title,
                  text: res.data.msg,
                  icon: 'warning',
                  confirmButtonText: 'Ok'
                })
              }
              this.apiGet()
              global.Swal.fire({
                title: 'Data Deleted Successfully!',
                icon: 'success',
              })
            })
            .catch((err)=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
              }
              this.doSetAlertForm(err);
            })
        }
      })
    },
    
    reparse(js){
      return $.parseJSON(JSON.stringify(js))
    },
    doPaginate(page = 1){
      if(page != this.$route.query.page){
        this.$set(this.filter, "page", page);
        this.$router.push({name:this.$route.name,query:_.clone(this.filter)})
      }
    },

    doSubmitnoSwal(action, input, callback=false, method="POST", IDFORM='VForm'){
      this.$refs[IDFORM].validate().then(success => {
      if (!success) { return; }
      
      if(this.loadingOverlay) return
      this.loadingOverlay = true 

      var data = $.parseJSON(JSON.stringify(input));
      var btnSubmit = $(".btn-loading");
      var prevHtml = btnSubmit.html();

      if($(".infoHTML").length){
        $(".infoHTML").removeClass('alert alert-danger alert-warning');
        $(".infoHTML").html('');
      }

      btnSubmit.html();
      Gen.apiRest(action, {data: $.extend({params:this.params}, data)}, method).then((res)=>{
          this.loadingOverlay = false
          let resp = res.data

          btnSubmit.html(prevHtml);

          resp.statusType = 200;
          this.doSetAlertForm(resp);
          
          if(callback){
            callback('success', resp)
          }

          setTimeout(()=>{ this.message="" },15000);
      }).catch((err)=>{
          this.loadingOverlay = false
          btnSubmit.html(prevHtml);
          
          if(err){
              err.statusType = err.status;
              err.status = "error";
              err.message = err.response?.data?.message;
              err.messageError = err.message
          }

          this.doSetAlertForm(err);
          if(this.failReset)
              this.$nextTick(() => {
                  this.$refs.form.reset();
                  this.message=""
              });
              
          if(callback){
            callback('error', err)
          }
      })
      });
    },
    
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm', isAdd){
      global.Swal.fire({
        icon: 'warning',
        title: `Are you sure to ${isAdd ? 'add' : 'update'} this data?`,
        showCancelButton: true,
        confirmButtonText: `Yes, Submit`,
        cancelButtonText: 'No Cancel',
        customClass: {
          confirmButton: 'btn btn-custom',
          cancelButton: 'btn btn-outline-custom ml-1',
        },
        buttonsStyling: false,
      }).then(res => {  
        if (res.value) {
          this.$refs[IDFORM].validate().then(success => {
          if (!success) { return; }
          
          if(this.loadingOverlay) return
          this.loadingOverlay = true 

          var data = $.parseJSON(JSON.stringify(input));
          var btnSubmit = $(".btn-loading");
          var prevHtml = btnSubmit.html();

          if($(".infoHTML").length){
            $(".infoHTML").removeClass('alert alert-danger alert-warning');
            $(".infoHTML").html('');
          }

          btnSubmit.html();
          Gen.apiRest(action, {data: $.extend({params:this.params}, data)}, method).then((res)=>{
              this.loadingOverlay = false
              let resp = res.data

              btnSubmit.html(prevHtml);

              resp.statusType = 200;
              this.doSetAlertForm(resp);
              
              if(callback){
                callback('success', resp)
              }

              setTimeout(()=>{ this.message="" },15000);
          }).catch((err)=>{
              this.loadingOverlay = false
              btnSubmit.html(prevHtml);
              
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response?.data?.message;
                  err.messageError = err.message
              }

              this.doSetAlertForm(err);
              if(this.failReset)
                  this.$nextTick(() => {
                      this.$refs.form.reset();
                      this.message=""
                  });
                  
              if(callback){
                callback('error', err)
              }
          })
          });
        }
      })  
    },
    doSubmitCRUD(IDForm='VForm'){
      return this.doSubmit(
          "/do/"+this.modulePage, 
          _.assign({type:this.isAdd?'add':'update'}, {id: (this.pageId||this.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              this.$router.push({name:this.$route.name})
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                }else{
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDForm, this.isAdd
      )
    },
    eType(e){return $.type(e)},
    doImport(){
      return this.doSubmit( "/do/"+this.modulePage, _.assign({type:'import-excel'}, {id:0}, _.clone(this.input)), (type, resp)=>{
          if(type=='success'){
            this.apiGet()
            this.$router.push({name:this.$route.name})
          }else{
            if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        }
      )
    },
    doSetAlertForm(resp){
      if(!resp.message) return;
      var panel = $(".infoHTML");
      if(resp.alert=="normal"){
        if(["success","warning"].indexOf(resp.status)>-1){
          panel.attr("class","infoHTML alert alert-"+resp.status);
        }else{
          panel.attr("class","infoHTML alert alert-danger");
        }
        panel.html("<div class='alert-body'>"+resp.message+"</div>");

        setTimeout(()=>{
          panel.html("");
          panel.attr("class","infoHTML");
        }, this.duration)
      }else {
        if($.inArray(resp.status,["success","warning"])!=-1){
          return global.Swal.fire({
            title: 'Information',
            text: resp.message,
            icon: resp.status,
            confirmButtonText: 'Ok'
          })
        }else{
          return global.Swal.fire({
            title: this.modulePage == 'Sorry, There is something wrong!',
            text: resp.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }
    },
    errorMessage(err){
      this.loadingOverlay = false
      let res = err.response
      if(res.status==422) return global.Swal.fire("Sorry, There is something wrong!", _.values(res.data)[0][0])
      global.Swal.fire("Sorry, There is something wrong!", res.data.message, "warning")
    },
    doLogout:function(){
      this.$store.commit("logoutUserToken")
      if(!this.userToken){
        this.$router.push({name:"Login"})
      }
    },
    uploader(filename,size) {
      if(!filename){
        return this.apiUrl + '/img/no-image.png';
      }
      var sizePath = size ? "/img/"+size+"/" : "/uploader/";
      return this.apiUrl + sizePath + (filename ? filename : "");
      // return this.apiUrl + '/uploader/' + filename;
    },
    configValue(data, id){
      let dt = $.grep((Config[data]||[]), (attr)=>{ return attr.id==id })
      return dt.length?dt[0].text:""
    },
    moduleRole(role){
      if(this.user.levelId==1) return true 
      return (this.user.moduleRole||[]).indexOf(role)>-1
    },
    menuRole(role){
      if(this.user.levelId==1) return true 
      return (this.user.menuRole||[]).indexOf(role)>-1
    },

    toggleSidebar(){
			document.querySelector("body").classList.toggle('sidebar-mobile-main');
			if (document.querySelector(".sidebar-main").classList.contains('sidebar-fullscreen')) {
				document.querySelector(".sidebar-main").classList.remove('sidebar-fullscreen')
			}
		},
		fullscreenSidebar(){
			document.querySelector(".sidebar-main").classList.toggle('sidebar-fullscreen');
		},
    tmEditorInit(val,reff=null) {
      if(reff){
       this.$refs[reff][0].editor.setContent(val);
       return;
      }
      if(this.$refs.tm.editor && val !== undefined)
        this.$refs.tm.editor.setContent(val);
    },
  },
  watch: {
    $route() {
      this.apiGetValProfile()  
    }
  }
}
</script>